.dialog-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(4px);
    background-color: rgba(255, 255, 255, 0.3);
  }
  
  .dialog-container.visible {
    display: flex;
  }
  
  .dialog-content {
    width: 33.33%;
    height: 33.33%;
    background-color: #FAFAFA;
    flex-direction: column;
    border-radius: 20px;
  }
  
  .dialog-header {
    display: flex;
    justify-content: flex-end;
  }
  
  .close-button {
    width: 24px;
    height: 24px;
    background-color: #FF5E41;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.75rem;
  }
  
  .close-button:hover {
    cursor: pointer;
  }
  
  .close-icon {
    color: #ffffff;
  }
  
  .dialog-body {
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 16px;
    color: black;
  
  }
  
  .add-subscription-button {
    width: 50%;
    height: 2.25rem;
  
    background-color: #FF5E41;
    border-radius: 1rem;
    color: #ffffff;
    text-align: center;
    padding: 0.5rem;
   margin-top: 40px;
  }
  
  .main-content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }