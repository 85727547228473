/* CarouselComponent.css */

.carousel-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.slick-slide {
  transition: transform 0.8s, opacity 0.8s;
  width: 300px
}

/* .slick-center .carousel-item {
  transform: scale(1.05);
  opacity: 1;
} */

.slick-prev, .slick-next {
  width: 50px; 
  height: 50px; 
  z-index: 1;
}

.slick-prev:before, .slick-next:before {
  font-size: 20px !important; /* Adjust for mobile */
  color: #FF5E41 !important;
}

/* .carousel-item {
  padding: 10px;
  padding:30px 0px;
} */

.pricing-card {
  padding-top: 20px;
  min-height: 476px;
  max-width: 300px;
  min-width: 320px;
  margin: 0 20px;
  background: #FFEEEB;
  border-radius: 30px;
  color: black;
  font-family: Arial, sans-serif;
  border: 1px solid #ddd;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.card-header h3 {
  font-size: 16px;
  font-weight: 600;
}



.card-header .price {
  font-weight: 600;
}

.card-header h2 {
  font-size: 28px;
  margin: 20px 0;
}

.card-features {
  padding: 0 15px;
  font-size: 16px;
  font-weight: 500;
  list-style-type: disc;
}

.card-features li {
  text-align: start;
  padding-top: 7px;
}

.card-features li::before {
  font-size: 20px;
  color: #ff0000;
}

.card-button {
  background-color: #FF5E41;
  font-size: 16px;
  width: 192px;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 5px 20px;
  margin-top: auto;
  align-self: center;
  cursor: pointer;
  transition: background-color 0.3s;
}

.pricing-card.active {
  transform: scale(1.05);
  background-color: #FF5E41;
  color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.pricing-card.active .card-button {
  background-color: #faf6f5;
  color: #FF5E41;
}
.pricing-card.active .price {
  font-size: 1.2em;
  color: #ffffff;
}

.card-button-mobile {
  background-color: #FF5E41;
  font-size: 16px;

  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 5px 20px;
  margin-top: auto;
  align-self: center;
  cursor: pointer;
  transition: background-color 0.3s;
}


@media (max-width: 768px) {
  .carousel-container {
    flex-direction: column;
    align-items: stretch;
  }

  .slick-slide {
    opacity: 1;
  }

  .slick-prev, .slick-next {
    width: 30px;
    height: 30px;
  }



  .slick-prev:before {
   
    position: absolute;
    left: 43px;
}

.slick-next:before {
   
  position: absolute;
  right: 43px;
}

  .slick-prev:before, .slick-next:before {
    font-size: 25px !important;
  }

  .carousel-item {
    padding: 5px;
  }

  .pricing-card {
    padding-top: 20px;
    min-height: 435px;
    max-width: 269px;
    min-width: 269px;
   
  }

  .card-button {
    width: 100%;
    padding: 10px;
  }
}


/* CarouselComponent.css */

/*rohit*/

.card {
  background-color: #FFEEEB;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 20px;
  width: 300px;

}

.popular-badge {
  background-color: #FF5E41;
  color: white;
  padding: 5px 10px;
  border-radius: 15px;
  margin-bottom: 10px;
  align-self: flex-start;
}

.card-header h3 {
  margin: 0;
  font-size: 1.5em;
}

.card-features {
  list-style: none;
  padding: 0;
  margin: 10px 0;
  text-align: left;
  width: 100%;
}

.card-features li {
  margin: 5px 0;
}

.card-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.price {
  font-size: 1.2em;
  color: #FF5E41;
}

.card-button {
  background-color: #FF5E41;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.card-button:hover {
  background-color: #E04C39;
}





.mobile-card {
  border-radius: 20px; /* Removed quotes */
  background-color: #f2f0f0; /* Changed comma to semicolon and removed quotes */
  margin:0px 5px; /* Removed quotes */
  padding: 10px 20px; /* Removed quotes */
}
.mobile-card.active {
  border: 5px solid  #FF5E41 ;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.card-header-mobile h3 {
  font-size: 17px;
  font-weight: 600;
}

@media (min-width: 769px) {
  .mobile {
    display: none !important;
  }
  .App {
    display: block;
  }
}

@media (max-width: 768px) {
  .mobile {
    display: block;
  }
  .App {
    display: none;
  }

}