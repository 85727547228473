

.button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3rem;
}

.container-button{
  color: white !important;
  background-color: #FF5E41;
  border-radius: 40px;
  border: 1px solid #ff5e41;
  min-height: 2rem;
  padding: 0px 25px;
  font-size: small;
  margin: 0px 5px;
}

.container-button-mobile{
  color: white !important;
  background-color: #FF5E41;
  border-radius: 32px;
  border: 1px solid #ff5e41;
  min-height: 1.5rem;
  padding: 0px 25px;
  font-size: small;
  margin: 0px 5px;
}

.info-box {
  background-color: #FFFFFF;
  border-radius: 30px;
  color: #FF5E41;
  font-weight: 400;
  font-size: 16px;
}




/* img, svg .iconcard{
  vertical-align: top;
} */