.pricing-card {
    padding-top: 20px;
    min-height: 435px;
    max-width: 289px;
    min-width: 289px;
    margin: 0 20px;
    background: #FFEEEB;

    border-radius: 30px;
    color: black;
    font-family: Arial, sans-serif;
    border: 1px solid #ddd;
    padding: 20px;
     display: flex;
    flex-direction: column;  

}

.card-header h3 {
    font-size: 16px;
    font-weight: 600;
}

.card-header .price {
    font-weight: 600;
}

.card-header h2 {
    font-size: 28px;
    margin: 20px 0;
}

.card-features {
    padding: 0 15px;
    font-size: 16px;
    font-weight: 500;
    list-style-type: disc;
}

.card-features li {
    text-align: start;
    padding-top: 7px;
}

.card-features li::before {
    font-size: 20px;
    color: #ff0000;
}

.card-button {
    background-color: #FF5E41;
    font-size: 16px;
    width:192px;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 5px 20px;
    margin-top: auto; /* Push the button to the bottom */
    align-self: center;
    cursor: pointer;
    transition: background-color 0.3s;
}


.pricing-card.active {
    transform: scale(1.10);
    background-color: #FF5E41;
    color: white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    .card-button {
        background-color: #faf6f5;
        color: #FF5E41;
    }
}